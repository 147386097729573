import React from "react";
import { Row, Col, Typography, Image } from "antd";
import "./StaticServicesSection.scss";
import DeliveryIcon from "../../assets/Icons/Delivery-icon.svg";
import CustomerServicesIcon from "../../assets/Icons/customer-services.svg";
import EasyReturnIcon from "../../assets/Icons/Easy-return.svg";

const StaticServicesSection = ({ userAgent }) => {
  const { isDesktop, isMobile } = userAgent;

  const Text = Typography;
  return (
    <Row justify="center" align="middle" className="service-section">
      <div className="service-section-inner">
        <Col span={8} className="service-item">
          <div className="service-icon">
            <Image
              preview={false}
              src={CustomerServicesIcon?.src}
              height={45}
              width="auto"
              alt="24/7 Customer Service"
            />
          </div>
          <div className="service-info">
            <Text className={isMobile ? "font12Bold" : "font20Bold"}>
              24/7 CUSTOMER SERVICE
            </Text>
            <p>Friendly 24/7 customer support</p>
          </div>
        </Col>
        <Col span={8} className="service-item">
          <div className="service-icon">
            <Image
              preview={false}
              src={EasyReturnIcon?.src}
              height={45}
              width="auto"
              alt="Easy Returns"
            />
          </div>
          <div className="service-info">
            <Text className={isMobile ? "font12Bold" : "font20Bold"}>
              Easy Returns
            </Text>
            <p>Easy Returns</p>
          </div>
        </Col>
        <Col span={8} className="service-item">
          <div className="service-icon">
            <Image
              preview={false}
              src={DeliveryIcon?.src}
              height={45}
              width="auto"
              alt="Fast Delivery"
            />
          </div>
          <div className="service-info">
            <Text className={isMobile ? "font12Bold" : "font20Bold"}>
              FAST DELIVERY
            </Text>
            <p>Free delivery for all orders over 5000</p>
          </div>
        </Col>
      </div>
    </Row>
  );
};

export default StaticServicesSection;
