import { Modal, Button, Typography, Image } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import { useState } from "react";
import "./DiscountModal.scss";
import { useRouter } from "next/navigation";
import Cookies from "js-cookie";
// import LandingPageImage from "../../../assets/images/NewLandingPage.png";
import PopupFeb from "../../../assets/images/Pop-up FEB FEST - Red.png";
import PopupPremier from "../../../assets/images/PremierPopup01.png";
// import RamadanPopup from "../../../assets/images/RamadanPopup.png";
import RamadanPopup from "../../../assets/images/Ramadan2.jpeg";
import IslamicPopupDesktop from "../../../assets/images/islamic-banner-image-desktop.jpg";
import IslamicPopupMobile from "../../../assets/images/islamic-banner-image-mobile.jpg";
import Vivo50Popup from "../../../assets/images/vivo-v50-popup-01.png";
import Vivov50Popup from "../../../assets/images/vivov50.png";

import Link from "next/link";

const DiscountModal = ({ openDiscountModal, showDiscountModal }) => {
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");
  const userType = Cookies.get("user_type");

  const router = useRouter();
  const Text = Typography;

  const handleClick = (type) => {
    sessionStorage.setItem("promoPopup", true);
    if (type === "shop-now") {
      router.push("/electronics");
    } else {
      showDiscountModal(false);
    }
  };

  // const getPopupImage = () => {
  //   switch (userType) {
  //     case "islamic":
  //       return RedTheme.src;
  //     case "premier":
  //       return PopupPremier.src;
  //     default:
  //       return PopupFeb.src;
  //   }
  // };

  console.log("user type", userType);

  const getPopupImage = () => {
    switch (userType) {
      case "islamic":
        return window.innerWidth > 768
          ? IslamicPopupDesktop.src
          : IslamicPopupMobile.src;
      case "premier":
        return PopupPremier.src;
      default:
        // return RamadanPopup.src;
        return Vivov50Popup.src;
    }
  };

  const getModalClass = () => {
    switch (userType) {
      case "islamic":
        return "discount-card-modal islamic-modal";
      case "premier":
        return "discount-card-modal premier-modal";
      default:
        return "discount-card-modal";
    }
  };

  return (
    <Modal
      open={openDiscountModal}
      onCancel={() => handleClick("close")}
      footer={null}
      centered
      closable={false} // Removes the close icon
      bodyStyle={{
        padding: 0,
      }}
      className={getModalClass()}
    >
      <div className="modal-image-container">
        <Link
          href={
            userType === "premier"
              ? "/premier"
              : "/vivo-flagship-store/vivo-v50-series-by-vivo-official"
          }
        >
          <Image
            src={getPopupImage()}
            preview={false}
            width="100%"
            height="100%"
            style={{ objectFit: "cover" }}
          />
        </Link>
        {/* add cross button */}
        <Button
          type="primary"
          className="discount-modal-button"
          onClick={() => handleClick("close")}
        >
          <CloseOutlined />
        </Button>
      </div>
    </Modal>
  );
};

export default DiscountModal;
