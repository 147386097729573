"use client";
//? Library Imports ------------------------------------------------------------>
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "antd";
import {
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../redux/Slices/multiLocationSlice";

import dynamic from "next/dynamic";

//? Assets --------------------------------------------------------------------->
import "../../components/Homepage/HomePage.scss";

//? Modules -------------------------------------------------------------------->
import TabsSection from "@/components/Homepage/TabsSection/TabsSection";
import BannerSwiper from "@/components/Homepage/BannerSwiper/BannerSwiper";
import BannerCarousel from "@/components/Homepage/BannerCarousel/BannerCarousel";
import CategoriesHeader from "@/components/Homepage/CategoriesHeader/CategoriesHeader";
import DiscountModal from "@/components/Homepage/DiscountModal/DiscountModal";

//? Redux Reducers ------------------------------------------------------------->
import { setShowMegaMenu } from "@/redux/Slices/megaMenuSlice";
import { Col, Row } from "antd";
import SearchForMobile from "@/components/shared/Search/MobileSearch/MobileSearchh";
import usePath from "@/hooks/usePath/usePath";
import { BsSearch } from "react-icons/bs";
import Navigation from "@/components/shared/Header/Navigation/Navigation";
import SideNavDrawer from "@/components/shared/Header/Navigation/SideNavDrawer/SideNavDrawer";
import MultiUsableLayout8 from "@/components/Homepage/MultiUsableLayout8/MultiUsableLayout8";
import SingleBanner from "@/components/Homepage/SingleBanner/SingleBanner";
import StaticServicesSection from "@/components/StaticServicesSection/StaticServicesSection";
import { customerLogout } from "@/functions/Cookies/Cookies";
import { logoutReducer } from "@/redux/Slices/accountSlice";
import useBrowserBackButton from "@/components/globalBackButton/BackButton";
import ImagesCarousel from "@/components/Homepage/ImagesCarousel/ImagesCarousel";
import { BrandsSlider } from "@/components/Homepage/BrandsSlider/BrandsSlider";

const MultiUsableComp = dynamic(() =>
  import("@/components/Homepage/MultiUsableComp/MultiUsableComp")
);

const ProductsCarousel = dynamic(() =>
  import("@/components/shared/ProductsCarousel/ProductsCarousel")
);

const BrandsCollection = dynamic(() =>
  import("@/components/Homepage/BrandsCollection/BrandsCollection")
);

const ProductsGroupComp = dynamic(() =>
  import("@/components/Homepage/ProductsGroupComp/ProductsGroupComp")
);

const CataloguesCarousel = dynamic(() =>
  import("@/components/Homepage/CataloguesCarousel/CataloguesCarousel")
);

const FilterByGenderSection = dynamic(() =>
  import("@/components/Homepage/FilterByGenderSection/FilterByGenderSection")
);
const BannerSection = dynamic(() =>
  import("@/components/Homepage/BannerSection/BannerSection")
);

export default function Homepage({
  storeId,
  data,
  userAgent,
  defaultCookiesCurrencyValue,
  defaultCookiesCountryValue,
  headerData,
  anouncementData,
  storefrontListData,
}) {
  const comverse_customer_name = useSelector(
    (state) => state.accountSlice?.comverse_customer_name
  );
  useBrowserBackButton(() => {});
  const customerToken = Cookies.get("comverse_customer_token");

  const path = usePath();
  if (Cookies.get("defaultCurrencyId") !== "23") {
    Cookies.set("defaultCurrencyId", storeId, {
      expires: 365,
    });
  }
  if (Cookies.get("defaultCurrencyName") !== "PKR") {
    Cookies.set("defaultCurrencyName", defaultCookiesCurrencyValue, {
      expires: 365,
    });
  }
  if (Cookies.get("defaultCountry") !== "Pakistan") {
    Cookies.set("defaultCountry", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  if (Cookies.get("geoLocation") !== "Pakistan") {
    Cookies.set("geoLocation", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  const mainColor = Cookies.get("main_theme");
  const secondaryColor = Cookies.get("secondary_theme");

  const defaultCurrencyIdFromCookies =
    storeId || Cookies.get("defaultCurrencyId");
  const [displayMobileSearchMenu, setDisplayMobileSearchMenu] = useState(false);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [showAcknowledgementModal, setShowAcknowledgementModal] =
    useState(false);
  const [showDiscountFirst, setShowDiscountFirst] = useState(false);
  const [discountShown, setDiscountShown] = useState(false);

  const test = Cookies.get("storeTest");
  const userType = Cookies.get("user_type");
  const isIslamicUser = userType === "islamic";
  const CookiesList = Cookies;
  const dispatch = useDispatch();
  const [homePageData, setHomePageData] = useState(data);
  const [showSideNav, setShowSideNav] = useState(true);
  const [DashboardDetails, setDashboardDetails] = useState({});
  const [openDiscountModal, setopenDiscountModal] = useState(false);

  const { isMobile, isTablet, isDesktop } = userAgent;

  const handleAcknowledge = () => {
    Cookies.set("user_acknowledgement", true);
    setShowAcknowledgementModal(false);
    setIsAcknowledged(true);
  };

  useEffect(() => {
    const userAcknowledgement = Cookies.get("user_acknowledgement");
    if (!userAcknowledgement) {
      if (isIslamicUser) {
        // For Islamic users, show discount first, then disclaimer
        setShowDiscountFirst(true);
        setShowAcknowledgementModal(false);
      } else {
        // For other users, show disclaimer directly
        setShowAcknowledgementModal(true);
      }
    } else {
      setIsAcknowledged(true);
    }
  }, []);

  useEffect(() => {
    // Handle the sequence of modals for Islamic users
    if (isIslamicUser && showDiscountFirst && discountShown) {
      setShowAcknowledgementModal(true);
      setShowDiscountFirst(false);
    }
  }, [discountShown, showDiscountFirst, isIslamicUser]);

  const fetchNextPage = async (page) => {
    const apiURL = isDesktop
      ? `${process.env.NEXT_PUBLIC_BACKEND}/storefront/homepage-desktop-new?storefront=${defaultCurrencyIdFromCookies}&page=${page}`
      : `${process.env.NEXT_PUBLIC_BACKEND}/storefront/homepage-mobile-new?storefront=${defaultCurrencyIdFromCookies}&page=${page}`;
    try {
      const response = await fetch(apiURL);
      if (!response.ok) throw new Error("API call failed");

      const pageData = await response.json();
      setHomePageData((prevData) => ({
        ...prevData,
        results: [...prevData.results, ...pageData.results],
        next: pageData.next,
      }));

      if (pageData.next !== null) {
        const nextPageNumber = new URL(pageData.next).searchParams.get("page");
        fetchNextPage(nextPageNumber);
      }
    } catch (error) {
      console.error("Error fetching page data:", error.message);
    }
  };
  useEffect(() => {
    fetchNextPage(2);
  }, [storeId]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // useEffect(function () {
  //   if (window?.location?.pathname !== "/") {
  //     document.querySelector(".footer-seo-description").style.display = "none";
  //   } else {
  //     document.querySelector(".footer-seo-description").style.display = "block";
  //   }
  // }, []);
  useEffect(() => {
    setTimeout(() => {
      if (path === "/") {
        setDisplayMobileSearchMenu(false);
        // document
        //   .querySelector(".mobile-bottom-nav")
        //   .classList.add("display-flex");
      }
    }, 700);
  }, [path]);

  useEffect(() => {
    setDisplayMobileSearchMenu(false);
  }, [path]);

  useEffect(() => {
    if (displayMobileSearchMenu) {
      document.querySelector("html").classList.add("hide-overflow");
      // document.querySelector("html").classList.add("hide-mobile-bottom-nav");
    } else {
      document.querySelector("html").classList.remove("hide-overflow");
      document.querySelector("html").classList.remove("hide-mobile-bottom-nav");
    }
    return () => {
      document.querySelector("html").classList.remove("hide-overflow");
      document.querySelector("html").classList.remove("hide-mobile-bottom-nav");
    };
  }, [displayMobileSearchMenu, window.location, path]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  //? Functions ------------------------------------------------------------------>
  const fetchDashboardDetails = async () => {
    if (customerToken) {
      try {
        const headers = {
          "Content-Type": "application/json",
        };
        headers.Authorization = customerToken;

        const payload = {
          method: "GET",
          headers: headers,
        };

        const res = await fetch(
          process.env.NEXT_PUBLIC_BACKEND + "/storefront/customer_dashboard",
          payload
        );

        const data = await res.json();

        if (!res.ok) {
          if (data?.detail === "Invalid auth token") {
            customerLogout();
            dispatch(logoutReducer());
            router.push("/");
          }
          //logout here
          throw new Error("Api Failed!");
        }

        setDashboardDetails(data);
        return data;
      } catch (error) {
        console.error("Error fetching data:", error.message);
        return null;
      }
    } else {
      setDashboardDetails({});
    }
  };

  const showDiscountModal = (flag) => {
    setopenDiscountModal(flag);
    if (!flag && showDiscountFirst) {
      // When discount modal is closed, mark it as shown
      setDiscountShown(true);
    }
  };

  //? Life Cycles ---------------------------------------------------------------->
  useEffect(() => {
    fetchDashboardDetails();
  }, [comverse_customer_name, customerToken]);

  useEffect(() => {
    const showPromo = sessionStorage.getItem("promoPopup");
    
    if (!showPromo && (!isIslamicUser || (isIslamicUser && !showDiscountFirst))) {
      // Show discount modal for non-Islamic users or Islamic users after disclaimer
      showDiscountModal(true);
    } else if (!showPromo && isIslamicUser && showDiscountFirst) {
      // Show discount modal for Islamic users before disclaimer
      showDiscountModal(true);
    }
  }, [isIslamicUser, showDiscountFirst]);

  return (
    <div
      className="Homepage"
      onMouseOver={() => dispatch(setShowMegaMenu(false))}
    >
      {!isAcknowledged && (
        <Modal
          className="disclaimer-modal"
          // title="Disclaimer"
          visible={showAcknowledgementModal}
          onCancel={handleAcknowledge}
          footer={[
            <Button
              key="agree"
              style={{ backgroundColor: mainColor }}
              type="primary"
              onClick={handleAcknowledge}
            >
              I ACKNOWLEDGE
            </Button>,
          ]}
          closable={false}
        >
          <h1 style={{ color: mainColor }}>Disclaimer</h1>
          <p>I acknowledge and allow the bank to:</p>
          <ol>
            <li>
              Access information which I have provided for the purposes of
              logistics and delivery i.e., name, phone number, and shipping
              address.
            </li>
            <li>
              Utilize and store said information on off-shore cloud service
              providers.
            </li>
            <li>
              Access said information for the purposes of promotional activities
              and marketing (if and only required).
            </li>
          </ol>
        </Modal>
      )}

      {/* Show DiscountModal for Islamic users before acknowledgment or for all users after acknowledgment */}
      {(isAcknowledged || (isIslamicUser && showDiscountFirst && !discountShown)) && (
        <DiscountModal
          showDiscountModal={showDiscountModal}
          openDiscountModal={openDiscountModal}
        />
      )}

      {displayMobileSearchMenu && (
        <Row>
          <Col flex="100%" style={{ overflowY: "scroll", height: "100vh" }}>
            <div>
              <SearchForMobile
                userAgent={{ isMobile, isTablet, isDesktop, userAgent }}
                setDisplayMobileSearchMenu={setDisplayMobileSearchMenu}
              />
            </div>
          </Col>
        </Row>
      )}
      {/* {section?.type === "tabs_section" && (
        <TabsSection
          data={section}
          userAgent={{ isMobile, isTablet, isDesktop }}
        />
      )} */}
      {homePageData?.results?.map((section, index) => {
        return (
          <div key={index}>
            {index === 0 && isMobile && (
              <div className="search-input-container">
                <BsSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search"
                  onClick={() => {
                    scrollToTop();
                    setDisplayMobileSearchMenu(true);
                  }}
                  className="search-input"
                />
              </div>
            )}
            {section?.type === "banner_slider" && (
              <>
                {!isMobile && section?.section_type === "layout_3" && (
                  <div className="marginBottom15 marginTop10">
                    <Row
                      style={{
                        // justifyContent: "space-between",
                        display: "flex",
                      }}
                    >
                      <Col span={5}>
                        <SideNavDrawer
                          anouncementData={anouncementData}
                          data={headerData}
                          showSideNav={showSideNav}
                          setShowSideNav={setShowSideNav}
                          userAgent={userAgent}
                        />
                      </Col>
                      <Col span={12} className="banner-swiper-alfa">
                        <BannerSwiper
                          data={section?.slides}
                          isMobile={isMobile}
                        />
                      </Col>
                      <Col span={6} className="banner-right">
                        <SingleBanner
                          link={section?.outside_banner_1_link}
                          BannerImage={section?.outside_banner_1}
                        />
                        <SingleBanner
                          link={section?.outside_banner_2_link}
                          BannerImage={section?.outside_banner_2}
                        />
                      </Col>
                    </Row>
                  </div>
                )}
                {isDesktop && section?.section_type === "layout_2" && (
                  <ImagesCarousel data={section} userAgent={userAgent} />
                )}

                {section?.section_type === "layout_1" && (
                  <BannerCarousel
                    DashboardDetails={DashboardDetails}
                    customerToken={customerToken}
                    data={section}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                  />
                )}
                {isMobile && section?.section_type === "layout_2" && (
                  <BannerCarousel
                    DashboardDetails={DashboardDetails}
                    customerToken={customerToken}
                    data={section}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                  />
                )}
              </>
            )}
            {/* {index === 0 && isMobile && (
              <div className="search-input-container">
                <BsSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search"
                  onClick={() => {
                    scrollToTop();
                    setDisplayMobileSearchMenu(true);
                  }}
                  className="search-input"
                />
              </div>
            )} */}

            {section?.type === "brands_slider" && (
              <BrandsSlider data={section} userAgent={userAgent} />
            )}
            {section?.type === "catalogues_carousel" && (
              <div
                style={{
                  maxWidth: "1232px",
                  margin: isDesktop ? "36px auto" : "",
                }}
              >
                <CataloguesCarousel
                  data={section}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                />
              </div>
            )}

            {section?.type === "products_carousel" && (
              <div
                className="product-carousel-main"
                style={{
                  margin: "auto",
                  maxWidth: "100%",
                }}
              >
                <ProductsCarousel
                  data={
                    section?.select_type === "collections_section"
                      ? section?.collections[0].products
                      : section?.select_type === "categories_section"
                      ? section?.categories[0].products
                      : null
                  }
                  categoryName={section?.categories[0]?.category_name}
                  title={section?.title}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                  type={"homepage"}
                />
              </div>
            )}

            {section?.type === "banner_section" && (
              <div>
                <BannerSection data={section} userAgent={userAgent} />
              </div>
            )}

            {/* {section?.type === "brands_slider" && (
              <div>
                <BrandsCollection data={section} userAgent={userAgent} />
              </div>
            )} */}
            {section?.type === "multi_usable_section" &&
              section?.section_type === "layout_8" && (
                <div>
                  <MultiUsableLayout8
                    data={section}
                    userAgent={{ isMobile, isTablet, isDesktop }}
                  />
                </div>
              )}
            {section?.type === "multi_usable_section" &&
              section?.section_type !== "layout_8" && (
                <MultiUsableComp
                  data={section}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                />
              )}

            {section?.type === "category_filters" && (
              <div>
                <FilterByGenderSection data={section} userAgent={userAgent} />
              </div>
            )}

            {/* {section?.type === "collections_section" && (
            <div>
              <ProductsGroupComp data={section} userAgent={userAgent} />
            </div>
          )} */}
            {section?.type === "collections_section" && (
              <div>
                <ProductsGroupComp data={section} userAgent={userAgent} />
              </div>
            )}
            {section?.type === "categories_header_section" && (
              <div>
                <CategoriesHeader data={section} userAgent={userAgent} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
